<template>
  <div>
    <div>
      <el-menu
        :default-active="$route.path"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        router
      >
         <!-- <el-menu-item index="/"
          ><i class="el-icon-menu"></i>
          <template v-slot:title>首页</template></el-menu-item
        > -->
        <el-menu-item index="/Papers">
          <i class="el-icon-document"></i>
          <template v-slot:title>试卷中心</template>
        </el-menu-item>
        <!-- <el-submenu index="2">
      <i class="el-icon-edit-outline"></i>
      <template v-slot:title> 考试记录 </template>
      <el-menu-item index="3-1">Shiny</el-menu-item>
      <el-menu-item index="3-2">LearnVue</el-menu-item>
      <el-menu-item index="3-3">OtherProject</el-menu-item>
    </el-submenu> -->
        <el-menu-item index="/MyDo"
          ><i class="el-icon-edit-outline"></i><template v-slot:title>考试记录</template></el-menu-item
        >
        <!--<el-menu-item index="/MyDo"
          ><i class="el-icon-question"></i><template v-slot:title>错题中心</template></el-menu-item
        >-->
        <el-menu-item index="/MarketPackage"
          ><i class="el-icon-sold-out"></i><template v-slot:title>套餐订购</template></el-menu-item
        >
        <el-menu-item index="/MyConsole"
          ><i class="el-icon-news"></i><template v-slot:title>个人中心</template></el-menu-item
        >
        <el-col :span="24" class="header">
          <el-col :span="18" class="logo">
            
             
                
             {{TerminalId}} 
             
            
          </el-col>
          <el-col :span="6" class="userinfo">
            <el-dropdown trigger="hover">
              <span class="el-dropdown-link userinfo-inner"
                ><img src="../../assets/user.png" />
              </span>
              <!--<template v-slot:dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>可用次数{{cnt}}</el-dropdown-item>
                  <el-dropdown-item>临时用户</el-dropdown-item>
                  
                </el-dropdown-menu>
              </template>-->
            </el-dropdown>
          </el-col>
        </el-col>
      </el-menu>
      <div>
        <router-view></router-view>
      </div>
      
    </div>
  </div>
  <!-- <el-menu :default-active="this.$route.path" router mode="horizontal">
    <el-menu-item v-for="(item, i) in navList" :key="i" :index="item.name">
      {{ item.navItem }}
    </el-menu-item>
    <Two />
  </el-menu> -->
</template>

<script>
//import Layout from '../../views/Layout.vue';
import global from '@/global';

export default {
  components: {  },
  data() {
    return {
      TerminalId:'', //终端
      cnt:'' //可使用次数
    };
  },
  //注意这里我是将它的改为这样的，效果是一样的，但使用这个可以在页面任何部位设置跳转时头部导航条部分样式会跟这变化即为选中，而不是不变的例如：你设置跳转到产品页导航条的样式也会跟着选中产品项
  // computed: {
  //   activeIndex() {
  //     return this.$route.path.replace("/", "");
  //   },
  // },
  mounted(){
      this.TerminalId = global.machineId;
      this.cnt = global.canUseNums;
  },
  methods: {
    handleSelect(key, keyPath) {
      //console.log(this);
      console.log(key, keyPath);
      this.$router.push({
        path: key,
        params: { data: "query" },
      });
    },
  },
  watch:{
    global:{
      handler(newValue,oldValue)
      {
        this.TerminalId = newValue.TerminalId;
        this.cnt = newValue.canUseNums;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  height: 20px;
  line-height: 20px;
  color: #fff;
  float: right;
  .userinfo {
    text-align: right;
    padding-right: 15px;
    float: right;
    .userinfo-inner {
      cursor: pointer;
      color: #fff;
      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin: 10px 10px 10px -10px;
        float: right;
      }
    }
  }
  .logo {
    height: 20px;
    font-size: 16px;
    padding-left: 10px;
    margin-top:20px;
    .txt {
      color: #fff;
    }
  }
}
</style>
